import React from "react";
import { Link } from "react-router-dom";
import { Block } from "components/shared/block";
import { Body } from "components/shared/typography";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Colors } from "colors";
import { Box, Hidden, IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Caption } from "./typography";
import { PatternBwCornerImage } from "components/images";
import { useDarkMode } from "contexts/themeMode";
import { HashLink } from "react-router-hash-link";
export const FooterWrapper = styled(Block)({
  height: 200,
  display: "flex",
  backgroundSize: "40vw",
  backgroundPosition: "bottom right",
  "@media (max-width: 960px)": {
    padding: "40px 20px",
    minHeight: "30vh",
  },
});

export const FooterContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  "@media (max-width: 320px)": {
    minWidth: 200,
    maxWidth: 240,
  },
});

export const IconContainer = styled(Box)({
  display: "none",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  "@media (max-width: 960px)": {
    display: "flex",
  },
});

const FooterColumn = styled(Box)({
  display: "flex",
  minWidth: 300,
  flexDirection: "column",
  marginBottom: 20,
  "@media (max-width: 320px)": {
    minWidth: 200,
    maxWidth: 200,
  },
});

interface ThemeProps {
  color?: string;
}

const FooterLink = styled(Link)<ThemeProps>(
  (props) => css`
    color: ${props.color || Colors.black};
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    &:after {
      content: "»";
      margin-left: 0.5rem;
    }
  `
);

const FooterHashLink = styled(HashLink)<ThemeProps>(
  (props) => css`
    color: ${props.color || Colors.black};
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    &:after {
      content: "»";
      margin-left: 0.5rem;
    }
  `
);



const FooterExternalLink = styled.a<ThemeProps>(
  (props) => css`
    color: ${props.color || Colors.black};
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    @media (max-width: 960px) {
      display: none;
    }
    &:after {
      content: "»";
      margin-left: 0.5rem;
    }
  `
);

const FooterIconButton = styled(IconButton)({
  width: 48,
});

export const Footer = () => {
  const darkMode = useDarkMode();
  return (
    <FooterWrapper
      backgroundColor={darkMode ? "black" : "cleanGrey"}
      backgroundImage={PatternBwCornerImage()}
    >
      <FooterContainer>
        <Hidden smDown>
          <FooterColumn>
            <Caption color={darkMode ? "white" : "black"}>SIDOR</Caption>
            <FooterLink to="/" color={darkMode ? "white" : "black"}>
              Start
            </FooterLink>
            <FooterLink to="/case" color={darkMode ? "white" : "black"}>
              Case
            </FooterLink>
            <FooterLink to="/om" color={darkMode ? "white" : "black"}>
              Om Logikfabriken
            </FooterLink>
            <FooterExternalLink href="https://jobs.logikfabriken.se" color={darkMode ? "white" : "black"}>
              Jobba hos oss
            </FooterExternalLink>
            <FooterHashLink
              to="/jobb#kontakt"
              color={darkMode ? "white" : "black"}
            >
              Kontakt
            </FooterHashLink>
          </FooterColumn>
        </Hidden>
        <FooterColumn>
          <Caption color={darkMode ? "white" : "black"}>Göteborg</Caption>
          <Body color={darkMode ? "white" : "black"}>
            <address>
              Logikfabriken AB
              <br />
              info@logikfabriken.se
              <br />
              <br />
              Kungsportsavenyen 29<br />
              411 36 GÖTEBORG
            </address>
          </Body>
          </FooterColumn>
          <FooterColumn>
          <Caption color={darkMode ? "white" : "black"}>Linköping</Caption>
          <Body color={darkMode ? "white" : "black"}>
            <address>
              Logikfabriken Linköping AB
              <br />
              info@logikfabriken.se
              <br />
              <br />
              Gamla Tanneforsvägen 92<br />
              582 52 Linköping
            </address>

          </Body>
        </FooterColumn>
        <FooterColumn>
          <Caption color={darkMode ? "white" : "black"}>FÖLJ OSS PÅ</Caption>

          <FooterExternalLink
            href="https://www.instagram.com/logikfabriken/"
            rel="noopener noreferrer"
            target="_blank"
            color={darkMode ? "white" : "black"}
          >
            Instagram
          </FooterExternalLink>
          <FooterExternalLink
            href="https://www.linkedin.com/company/logikfabriken-ab/"
            rel="noopener noreferrer"
            target="_blank"
            color={darkMode ? "white" : "black"}
          >
            LinkedIn
          </FooterExternalLink>
          <IconContainer>
            <a
              href="https://www.instagram.com/logikfabriken/"
              rel="noopener noreferrer"
              target="_blank"
              color={darkMode ? "white" : "black"}
            >
              <FooterIconButton aria-label="instagram">
                <InstagramIcon></InstagramIcon>
              </FooterIconButton>
            </a>
            <a
              href="https://www.linkedin.com/company/logikfabriken-ab/"
              rel="noopener noreferrer"
              target="_blank"
              color={darkMode ? "white" : "black"}
            >
              <FooterIconButton aria-label="linkedin">
                <FacebookIcon></FacebookIcon>
              </FooterIconButton>
            </a>
          </IconContainer>
        </FooterColumn>
      </FooterContainer>
    </FooterWrapper>
  );
};
